<template>
  <v-card tile @click="$router.push({name: 'branch', params: {branch_id: branchData.id, branch: branchData}})">
    <v-img :aspect-ratio="16/9" :src="branchData.site.cover ? branchData.site.cover : require('@/assets/images/no-image.png')"
           class="white--text"
           gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
           height="100%">
      <v-row align-content="space-between" class="h-100" no-gutters>
        <v-col cols="12">
          <v-row justify="space-between">
            <v-col cols="auto">
              <v-avatar :size="logoSize" class="ms-3">
                <v-img :src="branchData.site.logo"/>
              </v-avatar>
            </v-col>
            <v-col class="me-3" cols="auto">
              <v-btn :disabled="liking" class="btn-bg" dark icon @click.stop="favorite">
                <v-icon v-if="branchData.is_favorited">mdi-heart</v-icon>
                <v-icon v-else>mdi-heart-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer/>
        <v-col cols="12">
          <v-row align="center" class="action-bar pa-3" no-gutters>
            <v-col>
              <v-card-title class="pa-0" v-text="branchData.last_done_inspection ? branchData.last_done_inspection.grade : 'n/a'"/>
              <v-card-title class="pa-0 text-subtitle-1 text-start"
                            v-text="branchData.site.name + ' #' + branchData.number"/>
            </v-col>

            <v-btn :disabled="liking" dark icon @click.stop="like(1)">
              <v-icon v-if="isLiked">mdi-thumb-up</v-icon>
              <v-icon v-else>mdi-thumb-up-outline</v-icon>
            </v-btn>
            <span class="me-2 ms-1">{{ branchData.likes_count }}</span>
            <v-btn :disabled="liking" dark icon @click.stop="like(0)">
              <v-icon v-if="isDisliked">mdi-thumb-down</v-icon>
              <v-icon v-else>mdi-thumb-down-outline</v-icon>
            </v-btn>
            <span class="me-2 ms-1">{{ branchData.dislikes_count }}</span>
          </v-row>
        </v-col>
      </v-row>

    </v-img>
  </v-card>
</template>

<script>

import Branch from '@/modules/app/models/branch'
import { SHOW_SNACKBAR_ERROR } from '@/modules/app/store/mutation-types'

export default {
  name: 'KurccSiteCard',
  props: {
    branch: {
      type: Branch,
      required: true
    }
  },
  data () {
    return {
      localBranch: null,
      liking: false
    }
  },
  computed: {
    isLiked () {
      return this.branchData.like_status === true
    },
    isDisliked () {
      return this.branchData.like_status === false
    },
    branchData () {
      return this.localBranch ?? this.branch
    },
    logoSize () {
      if (this.$vuetify.breakpoint.xs) {
        return 50
      } else if (this.$vuetify.breakpoint.smAndUp) {
        return 65
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return 90
      } else {
        return 90
      }
    },
    isUserAuthenticated () {
      return this.$auth.check()
    }
  },
  methods: {
    like (value) {
      if (!this.isUserAuthenticated) {
        this.$store.commit(SHOW_SNACKBAR_ERROR, 'Please login to vote')
        return
      }

      this.liking = true
      this.$store.dispatch('likeBranch', {
        id: this.branchData.id,
        like_status: value
      }).then(res => {
        this.localBranch = res
      }).finally(() => {
        this.liking = false
      })
    },
    favorite () {
      if (!this.isUserAuthenticated) {
        this.$store.commit(SHOW_SNACKBAR_ERROR, 'Please login to favorite')
        return
      }

      this.liking = true
      this.$store.dispatch('favoriteBranch', {
        id: this.branchData.id,
        is_favorited: !this.branchData.is_favorited
      }).then(res => {
        this.localBranch = res
      }).finally(() => {
        this.liking = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.action-bar {
  background: rgba(0, 0, 0, 0.5);
}

.btn-bg {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
